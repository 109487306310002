.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#wbproot
{
  font-size: 10px;
//font-family: "Quicksand";
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.CalendarDay
{
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

.DayPicker_weekHeader_li {
  margin-left: 0px !important;
  margin-right: 0px !important;

  padding-left: 0px !important;
  padding-right: 0px !important;
}

.DayPicker_weekHeader_ul {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}


.CalendarMonth_table
{
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

.mbsc-calendar-day-empty.check-in
{
  background: #fff !important;

}

.mbsc-calendar-day-empty.check-out
{
  background: #fff !important;
}


.mbsc-datepicker .mbsc-ltr.check-in {
  background: linear-gradient(
          to left top,
          #eeeeee 50%,
          transparent 50%) no-repeat ;
}
.mbsc-datepicker .mbsc-ltr.check-out {
  background: linear-gradient(
          to left top,
          transparent 50%,
          #eeeeee 50%) no-repeat ;
}
.mbsc-datepicker .mbsc-rtl.check-in {
  background: linear-gradient(
          to right bottom,
          #eeeeee 50%,
          transparent 50%) no-repeat ;
}


.mbsc-datepicker .mbsc-rtl.check-out {
  background: linear-gradient(
          to right bottom,
          transparent 50%,
          #ffbaba80 50%) no-repeat ;
}
.mbsc-datepicker .mbsc-ltr.check-out,
.mbsc-datepicker .mbsc-rtl.check-in {
  border-color: #46c4f3;
}
.mbsc-datepicker .mbsc-windows.check-in,
.mbsc-datepicker .mbsc-windows.check-out {
  border-color: transparent;
}
